import React from 'react';
import classes from './style.module.scss';
import {MachineSpecification} from "~/types";
import useTranslation from "~/app/hooks/useTranslation";

export interface ListItemProps {
    item: Partial<MachineSpecification>
    itemKey: string
}

export type ListItemComponent = React.FC<ListItemProps>

const ListItem: ListItemComponent = ({itemKey, item}) => {

    const {t} = useTranslation("documentation.specifications");

    return (
        <div className={`${classes['list-item']}`}>
            <div className={classes['title']}>{t(`${itemKey}`)}</div>
            <div className={classes['value']}>{item !== '' && item !== null ? item : '-'}</div>
        </div>
    );
};

export default ListItem
