import React from 'react';
import {useStoreState} from '~/store';
import AccessControl from '~/accessControl';
import CertificationInfo from '../CertificationInfo';
import { PERMISSION_GROUPS } from '~/accessControl/permissionGroups';
import HeaderOfflineSwitch from '../../HeaderOfflineSwitch';

export type HeaderComponent = React.FC

const Header: HeaderComponent = () => {
    const machine = useStoreState(state => state.machine.data);

    return (
        <>
            <AccessControl requiredPermissions={PERMISSION_GROUPS.machineRegistration}>
                <AccessControl requiredPermissions={PERMISSION_GROUPS.readCertificationInfo}>
                    <CertificationInfo certification={machine.certification}/>
                </AccessControl>
                <AccessControl requiredPermissions={PERMISSION_GROUPS.readADRCertificationInfo}>
                    <CertificationInfo certification={machine.adrCertification}/>
                </AccessControl>
                <HeaderOfflineSwitch />
            </AccessControl>
        </>
    );
};

export default Header
