import React, {useCallback, useEffect, useMemo, useState} from 'react';
import MForm from '~/app/modules/form';
import {required, validateOperatingTimeOrCount} from '~/app/helpers/validation';
import useTranslation from '~/app/hooks/useTranslation';
import useForm, {UseFormOptionFields} from '~/app/modules/form/useForm';
import Text from '~/app/modules/form/Text';
import classes from './style.module.scss';
import {OperatingUnitTypePayload} from "~/types";
import {useStoreState} from '~/store';
import ValidationMessage, {ValidationMessageProps} from './ValidationMessage';
import Footer from './Footer';
import { OPERATING_COUNT_UNIT_NAME, OperatingTypes } from '~/app/data/statuses/operatingData';
import { OperatingTimePayload } from '~/machines/store/machine';

export interface FormProps {
    onSelect: () => void
    operatingUnitType: OperatingUnitTypePayload
}

export type FormComponent = React.FC<FormProps>
const Form: FormComponent = ({onSelect, operatingUnitType}) => {
    const requiredValidation = useCallback(required, []);
    const [validation, SetValidation] = useState<ValidationMessageProps>({status: 'invalid', operatingUnitType});
    const {t} = useTranslation(operatingUnitType === OperatingTypes.OPERATING_COUNT ? 'operatingCount' : 'operatingTime');
    const machine = useStoreState(state => state.machine.data);
    const operatingFormType = operatingUnitType === OperatingTypes.OPERATING_COUNT ? OPERATING_COUNT_UNIT_NAME : machine.operatingType;

    const formFields = useMemo<UseFormOptionFields>(() => ([
        ['operatingTime', {validators: [['required', requiredValidation]]}],
    ]), [requiredValidation]);

    const {formState, fieldEvents, values} = useForm<Partial<OperatingTimePayload>>({
        formName: 'registerOperatingData',
        fields: formFields,
        validationTrigger: 'onBlur',
        defaultValues: {operatingTime: ''},
    });

    useEffect(() => {
        const warning = validateOperatingTimeOrCount(operatingUnitType === OperatingTypes.OPERATING_COUNT ? machine.operatingCount : machine.operatingTime, values ? values.operatingTime : 0);
        const className = (formState.touched) ? ((formState.invalid) ? 'invalid' : ((warning) ? 'warning' : 'valid')) : '';
        SetValidation({status: className, ...(warning && {warning: warning}), operatingUnitType});
    }, [formState, values, machine, operatingUnitType]);

    return (
        <MForm className={`${classes['model-body']}`}>
            <label
                className={`${classes['form-label']} ${classes[validation.status]}`}>{t(`${operatingFormType}`)}</label>
            <Text
                className={`${classes[validation.status]} ${classes['input-box']}`}
                inputClassName={`${classes['input']} ${classes[validation.status]}`}
                name="operatingTime"
                type="number"
                label={operatingFormType}
                placeholder={t('quantity')}
                autoComplete="off"
                {...fieldEvents}
            />

            <ValidationMessage {...validation}/>
            <Footer onSelect={onSelect} operatingUnitType={operatingUnitType} />
        </MForm>
    );
};

export default Form