import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useStoreActions, useStoreState} from '~/store';
import {LastPeriodicCheck, Machine, OperatingUnitTypePayload, Project} from "~/types";
import Header from "./Header";
import List, {ListProps} from "./List";
import useTranslation from "~/app/hooks/useTranslation";
import moment from 'moment';
import RegisterOperatingTime from '~/operatingTime/Register';
import AddIssue, {AddIssueProps} from '~/issues/Add';
import {ItemProps} from "./List/ListItem";
import {route} from "~/app/helpers/route";
import {MACHINES_DETAILS_ROUTES} from "~/machines/Details/Router";
import {useHistory} from "react-router";
import {PERMISSION_GROUPS} from "~/accessControl/permissionGroups";
import useAccessControl from "~/app/hooks/useAccessControl";
import {InspectionTypes} from "~/periodicCheck/store";
import { OPERATING_COUNT_UNIT_NAME, OperatingTypes } from '~/app/data/statuses/operatingData';
import Loader from '~/app/common/Loader';
import { useAppHeader } from '~/app/modules/appHeader';
import { getMachineSubTitle } from '~/machines/helpers';
import { MACHINES_ROUTES } from '~/machines/Router';

export type RegistrationComponent = React.FC<Pick<ListProps, 'onSelect'>>

const Registration: RegistrationComponent = () => {
    const history = useHistory();
    const {checkPermission} = useAccessControl();
    const today = moment();
    const {t} = useTranslation("machine.details");
    const {id, projectId} = useParams<{ id: Machine['id'], projectId: Project['id'] }>();
    const [openOperatingTimeDialog, setOpenOperatingTimeDialog] = useState<boolean>(false);
    const [openAddIssueDialog, setAddIssueDialog] = useState<boolean>(false);
    const [issueType, setIssueType] = useState<AddIssueProps['type']>('issue');
    const machine = useStoreState(state => state.machine.data);
    const nextService = useStoreState(state => state.machine.nextService);
    const loadChecksData = useStoreActions(actions => actions.periodicCheck.loadChecksData);
    const machineService = useStoreActions(actions => actions.machine.fetchMachineService);
    const netWorkStatus = useStoreState(state => state.app.offline.status);
    const [operatingUnitType, setOperatingUnitType] = useState<OperatingUnitTypePayload>(0);
    const fetching = useStoreState(state => state.machine.fetching);

    useEffect(() => {
        if(id && netWorkStatus === 0 && checkPermission(PERMISSION_GROUPS.readServiceDetails)) {
            machineService(id)
        }
    }, [id, netWorkStatus, machineService, checkPermission]);

    const lastCheckStatus = useCallback((lastCheck: LastPeriodicCheck | undefined): ItemProps['subTitle'] => {
        if (lastCheck && lastCheck.date) {
            if (moment(lastCheck.date).isSame(today, 'day')) {
                return {name: t('periodicCheckToday'), checked: true};
            } else {
                return {name: t('periodicCheckPast', {date: moment(lastCheck.date)})};
            }
        }
        return null;

    }, [t, today])

    const toggleOperatingDataDialog = useCallback((type: OperatingUnitTypePayload) => {
        setOpenOperatingTimeDialog(!openOperatingTimeDialog);
        setOperatingUnitType(type);
    }, [setOpenOperatingTimeDialog, openOperatingTimeDialog, setOperatingUnitType]);

    const toggleAddIssueDialog = useCallback(() => {
        setAddIssueDialog(!openAddIssueDialog);
    }, [setAddIssueDialog, openAddIssueDialog]);

    const openIssueDialog = useCallback(() => {
        setIssueType('issue');
        toggleAddIssueDialog();
    }, [setIssueType, toggleAddIssueDialog]);

    const openRepairDialog = useCallback(() => {
        setIssueType('repair');
        toggleAddIssueDialog();
    }, [setIssueType, toggleAddIssueDialog]);

    const showChecks = useCallback((checks, inspectionType, lastRegisteredCheck) => {
        loadChecksData({checks, inspectionType, lastRegisteredCheck});
        history.push(route(MACHINES_DETAILS_ROUTES.periodicCheck, {id: machine.id, projectId}))
    }, [history, loadChecksData, machine, projectId]);

    const handleOpenMachineService = useCallback(() => {
        history.push(route(MACHINES_DETAILS_ROUTES.nextService, {id: machine.id, projectId}))
    }, [history, machine, projectId]);

    const nextServiceInfo = useMemo(() => {
        const service: any = {
            subtitle: {name:'-'},
            status: null
        }
        if(nextService.status){
            service.subtitle = {name: `Om: ${nextService.dueHoursText}`}
            service.status = {name:`Status: ${nextService.workOrderStatus}`}
        }

        return service
    },[nextService])

    const items = useMemo<ItemProps[]>(() => ([
        {
            icon: 'pending_actions',
            title: t('serviceInfo'),
            subTitle: nextServiceInfo.subtitle,
            thirdTitle: nextServiceInfo.status,
            rightIcon: 'keyboard_arrow_right',
            className: 'bottom-space',
            onSelect: handleOpenMachineService,
            requiredPermissions: PERMISSION_GROUPS.readServiceDetails
        },
        ...!!machine.isOperatingTimeVisible ? [{
            icon: 'speed',
            title: t(machine.categoryType === 'vehicle' ? 'operatingKm' : 'operatingTime'),
            subTitle: {
                name: t((moment(machine.lastOperatingTimeDate).isSame(moment(), 'day') ? 'operatingTimeToday' : 'operatingTimePast'), {
                    operatingTime: machine.operatingTime,
                    type: machine.operatingType
                }),
                checked: moment(machine.lastOperatingTimeDate).isSame(moment(), 'day')
            },
            rightIcon: 'add',
            ...!checkPermission(PERMISSION_GROUPS.readWritePeriodicCheck) && (!checkPermission(PERMISSION_GROUPS.readWriteOperatingCount) || !machine.isOperatingCountVisible) && {className: 'bottom-space'},
            onSelect: () => toggleOperatingDataDialog(OperatingTypes.OPERATING_TIME),
            requiredPermissions: PERMISSION_GROUPS.readWriteOperatingTime
        }] : [],
        ...!!machine.isOperatingCountVisible ? [{
            icon: 'sensors',
            title: t('operatingCount'),
            subTitle: {
                name: t((moment(machine.lastOperatingCountDate).isSame(moment(), 'day') ? 'operatingCountToday' : 'operatingCountPast'), {
                    operatingCount: machine.operatingCount,
                    type: OPERATING_COUNT_UNIT_NAME
                }),
                checked: moment(machine.lastOperatingCountDate).isSame(moment(), 'day')
            },
            rightIcon: 'add',
            ...!checkPermission(PERMISSION_GROUPS.readWritePeriodicCheck) && {className: 'bottom-space'},
            onSelect: () => toggleOperatingDataDialog(OperatingTypes.OPERATING_COUNT),
            requiredPermissions: PERMISSION_GROUPS.readWriteOperatingCount
        }] : [],
        {
            icon: 'event_available',
            title: t('periodicCheck'),
            subTitle: lastCheckStatus(machine.lastDailyCheck),
            rightIcon: 'add',
            className: 'bottom-space',
            onSelect: () => showChecks(machine.checks, machine.inspectionType, machine.lastDailyCheck),
            requiredPermissions: PERMISSION_GROUPS.readWritePeriodicCheck
        },
        {
            icon: 'error_outline',
            title: t('errorStatus'),
            rightIcon: 'keyboard_arrow_right',
            ...!checkPermission(PERMISSION_GROUPS.registerIssues) && !checkPermission(PERMISSION_GROUPS.registerRepairByDriver) && {className: 'bottom-space'},
            subTitle: {name: 'Ingen åpne feil'},
            statuses: machine.issuesCount,
            url: route(MACHINES_DETAILS_ROUTES.issues, {id: machine.id}),
            requiredPermissions: PERMISSION_GROUPS.readIssues
        },
        {
            icon: 'report_problem',
            title: t('registerError'),
            rightIcon: 'add',
            ...!checkPermission(PERMISSION_GROUPS.registerRepairByDriver) && {className: 'bottom-space'},
            onSelect: openIssueDialog,
            requiredPermissions: PERMISSION_GROUPS.registerIssues
        },
        {
            icon: 'build',
            title: t('registerRepairByDriver'),
            rightIcon: 'add',
            className: 'bottom-space',
            onSelect: openRepairDialog,
            requiredPermissions: PERMISSION_GROUPS.registerRepairByDriver
        },
        {
            icon: 'assignment',
            title: t('receptionCheck'),
            subTitle: lastCheckStatus(machine.lastAcceptanceInspection),
            rightIcon: 'add',
            className: 'bottom-space',
            onSelect: () => showChecks(machine.acceptanceInspectionChecks, InspectionTypes.ACCEPTANCE_INSPECTION, machine.lastAcceptanceInspection),
            requiredPermissions: PERMISSION_GROUPS.performAcceptanceInspection
        },
        {
            icon: 'error_outline',
            title: t('informationAndDocuments.title'),
            subTitle: {name: t('informationAndDocuments.description')},
            rightIcon: 'keyboard_arrow_right',
            url: route(MACHINES_DETAILS_ROUTES.documentation, {id: machine.id, projectId}),
            requiredPermissions: PERMISSION_GROUPS.readMachineDocuments
        },
        //{icon: 'assignment', title: t('receptionCheck'), rightIcon: 'add'},
    ]), [
        machine,
        openRepairDialog,
        toggleOperatingDataDialog,
        lastCheckStatus,
        openIssueDialog,
        showChecks,
        checkPermission,
        t,
        projectId,
        nextServiceInfo,
        handleOpenMachineService
    ]);

    const {setData} = useAppHeader();

    useEffect(() => {
        setData({
            backLink: route(MACHINES_ROUTES.machines, {projectId}),
            title: (machine.name || '').toUpperCase(),
            subTitle: getMachineSubTitle(machine),
            showUser: false,
            machineId: netWorkStatus === 0 ? id : '',
            projectId: '',
            parentFolderId: 0,
            showMachineMessages: true,
            isIssueDetailPage: false,
            notification: true,
            search: false
        });
    }, [setData, t, id, machine, projectId, netWorkStatus]);

    if (fetching) {
        return <Loader size="32" />;
    }

    return (
        <>
            <Header/>
            <List list={items}/>
            <RegisterOperatingTime open={openOperatingTimeDialog} onClose={() => toggleOperatingDataDialog(operatingUnitType)} operatingUnitType={operatingUnitType} />
            <AddIssue open={openAddIssueDialog} onClose={toggleAddIssueDialog} type={issueType} />
        </>
    );
};

export default Registration
