import axios from '~/axiosWrapper';
import {action, Action, computed, Computed, thunk, Thunk} from 'easy-peasy';
import {CommonModel, getCommonModel, fetch, fetchCancel} from 'store/commonStore';
import {API} from 'app/routes';
import {StoreModel} from 'store/index';
import {Machine} from 'types/index';
import {MachineOfflineService} from "~/sqlite/services/machine";

interface MachinesFavoritePayload {
    machineId: Machine['id'],
    favorite: boolean
}

interface MachinesLastUsedPayload {
    machineId: Machine['id']
}

export interface MachinesModel extends CommonModel<Machine> {
    
    filteredList: Computed<MachinesModel, Machine[], StoreModel>

    lastUsed: Machine[]
    fetchingLastUsed: boolean
    setFetchingLastUsed: Action<MachinesModel, boolean>
    fetchLastUsed: Thunk<MachinesModel, void, any, StoreModel, Promise<Machine[]>>
    fetchLastUsedCancel: Action<MachinesModel>
    fetchedLastUsed: Action<MachinesModel, Machine[]>

    inFavorite: Computed<MachinesModel, (id: Machine['id']) => boolean, StoreModel>
    favorites: Machine[]
    fetchingFavorites: boolean
    setFetchingFavorites: Action<MachinesModel, boolean>
    fetchFavorites: Thunk<MachinesModel, void, any, StoreModel, Promise<Machine[]>>
    fetchFavoritesCancel: Action<MachinesModel>
    fetchedFavorites: Action<MachinesModel, Machine[]>

    favoritesUpdating: boolean
    setFavoritesUpdating: Action<MachinesModel, boolean>
    updateFavorites: Thunk<MachinesModel, MachinesFavoritePayload, any, StoreModel, Promise<null>>
    updatedFavorites: Action<MachinesModel, Machine[]>

    updateLastUsed: Thunk<MachinesModel, MachinesLastUsedPayload, any, StoreModel, Promise<null>>

    setOfflineFavorites: Thunk<MachinesModel, Machine[], any, StoreModel, Promise<void>>
    getOfflineFavorites: Thunk<MachinesModel, void, any, StoreModel, Promise<void>>
}

export const machinesModel: MachinesModel = {
    ...getCommonModel<Machine>(API.machines),

    filteredList: computed(state => state.list.filter(item => (
        !state.lastUsed.find(({id}) => item.id === id) &&
        !state.favorites.find(({id}) => item.id === id)
    ))),

    lastUsed: [],
    fetchingLastUsed: false,
    setFetchingLastUsed: action((state, payload) => {
        state.fetchingLastUsed = payload;
    }),
    fetchLastUsed: thunk((actions) => {
        actions.setFetchingLastUsed(true);

        return fetch<Machine>(`${API.machines}/last-used`)
            .then(data => {
                actions.fetchedLastUsed(data);
                return data;
            })
            .finally(() => {
                actions.setFetchingLastUsed(false);
            });
    }),
    fetchLastUsedCancel: action(() => {
        fetchCancel('Operation canceled by the user');
    }),
    fetchedLastUsed: action((state, payload) => {
        state.lastUsed = payload;
    }),

    inFavorite: computed(
        state => (id: Machine['id']): boolean => !!state.favorites.find(item => item.id.toString() === id.toString())
    ),
    favorites: [],
    fetchingFavorites: false,
    setFetchingFavorites: action((state, payload) => {
        state.fetchingFavorites = payload;
    }),
    fetchFavorites: thunk((actions) => {
        actions.setFetchingFavorites(true);

        return fetch<Machine>(`${API.machines}/favorite`)
            .then(data => {
                actions.fetchedFavorites(data);
                actions.setOfflineFavorites(data);

                return data;
            })
            .finally(() => {
                actions.setFetchingFavorites(false);
            });
    }),
    fetchFavoritesCancel: action(() => {
        fetchCancel('Operation canceled by the user');
    }),
    fetchedFavorites: action((state, payload) => {
        state.favorites = payload;
    }),

    favoritesUpdating: false,
    setFavoritesUpdating: action((state, payload) => {
        state.favoritesUpdating = payload;
    }),
    updateFavorites: thunk((actions, payload) => {
        actions.setFavoritesUpdating(true);

        return axios.patch<null, null>(`${API.machines}/favorite`, payload).then(
            data => fetch<Machine>(`${API.machines}/favorite`)
                .then(list => {
                    actions.updatedFavorites(list);
                    actions.setOfflineFavorites(list);

                    return data;
                })
                .finally(() => {
                    actions.setFavoritesUpdating(false);
                })
        )
    }),
    updatedFavorites: action((state, payload) => {
        state.favorites = payload;
    }),

    updateLastUsed: thunk((actions, payload) => {
        return axios.post<null, null>(`${API.machines}/last-used`, payload);
    }),

    setOfflineFavorites: thunk(async (actions, payload) => {
        const MachineOffline = new MachineOfflineService();
        await MachineOffline.reset(payload);
    }),

    getOfflineFavorites: thunk(async (actions) => {
        const MachineOffline = new MachineOfflineService();
        let machines = await MachineOffline.getAll();
        machines && actions.updatedFavorites(
            machines.map(
                (machine) =>
                    ({
                        ...JSON.parse(machine.data),
                        id: machine.id,
                        categoryImage: `${window['cordova'].file.dataDirectory}/${machine.categoryImage}`
                    })
            )
        );
    }),
};
