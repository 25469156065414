import {action, Action, thunk, Thunk} from 'easy-peasy';
import {Machine, MachineService, MachineActiveTabs, OperatingUnitTypePayload} from '~/types';
import {StoreModel} from "~/store";
import axios from "~/axiosWrapper";
import {API} from "~/app/routes";
import {CommonModel, getCommonModel} from "~/store/commonStore";
import {MachineOfflineService} from "~/sqlite/services/machine";
import { localStorageGet, localStorageSet } from '~/app/helpers/localStorage';
import { MachineTabs } from '~/app/data/statuses/machine';
import { checkPermission } from '~/accessControl/helpers';
import { PERMISSION_GROUPS } from '~/accessControl/permissionGroups';
import { Moment } from 'moment';

export interface OperatingTimePayload {
    machineId: Machine['id'],
    operatingTime: Machine['operatingTime'] | Machine['operatingCount'],
    type: OperatingUnitTypePayload,
    sync?: number
}

export interface MachineModel extends CommonModel<Machine> {
    tabs: MachineActiveTabs['activeTab'][]
    defaultTab: MachineActiveTabs['activeTab']
    setDefaultTab: Action<MachineModel, MachineActiveTabs['activeTab']>
    activeTab: MachineActiveTabs['activeTab']
    setActiveTab: Action<MachineModel, MachineActiveTabs['activeTab']>
    
    dataNotAvailableSinceDate: Moment | null
    setDataNotAvailableSinceDate: Action<MachineModel, Moment | null>
    
    data: Machine
    nextService: MachineService
    fetchMachine: Thunk<MachineModel, Machine['id'], any, StoreModel, Promise<void>>
    fetchedMachine: Action<MachineModel, Machine>
    registerOperatingTimeOrCount: Thunk<MachineModel, OperatingTimePayload, any, StoreModel, Promise<void>>
    fetchMachineService: Thunk<MachineModel, string | number, any, StoreModel, Promise<void>>
    setMachineService: Action<MachineModel, MachineService>
    fetchOfflineMachine: Thunk<MachineModel, Machine['id'], any, StoreModel, Promise<void>>
    setOfflineMachine: Thunk<MachineModel, Machine, any, StoreModel, Promise<void>>
    offlineRegisterOperatingTimeOrCount: Thunk<MachineModel, OperatingTimePayload, any, StoreModel, Promise<void>>
    
    fetchMachineDashboard: Thunk<MachineModel, Machine['id'], any, StoreModel, Promise<void>>
    fetchedMachineDashboard: Action<MachineModel, Machine>
    fetchOfflineMachineDashboard: Thunk<MachineModel, Machine['id'], any, StoreModel, Promise<void>>
    setOfflineMachineDashboard: Thunk<MachineModel, Machine, any, StoreModel, Promise<void>>
    initActiveTab: Thunk<MachineModel, void, any, StoreModel, void>
    resetActiveTab: Thunk<MachineModel, void, any, StoreModel, void>
}

export const machineModel: MachineModel = {
    ...getCommonModel<Machine>(API.machines),
    tabs: [MachineTabs.DASHBOARD, MachineTabs.REGISTRATION],
    defaultTab: MachineTabs.EMPTYTAB,
    setDefaultTab: action((state, payload) => {
        state.defaultTab = payload === MachineTabs.DASHBOARD || payload === MachineTabs.REGISTRATION ? payload : MachineTabs.EMPTYTAB;
    }),
    activeTab: localStorageGet('lastVisitedTab') as MachineTabs || MachineTabs.EMPTYTAB,
    setActiveTab: action((state, payload) => {
        const defaultTab = payload === MachineTabs.DASHBOARD || payload === MachineTabs.REGISTRATION ? payload : MachineTabs.EMPTYTAB;
        state.activeTab = defaultTab;
        defaultTab.length && localStorageSet('lastVisitedTab', defaultTab);
    }),
    dataNotAvailableSinceDate: null,
    setDataNotAvailableSinceDate: action((state, payload) => {
        state.dataNotAvailableSinceDate = payload;
    }),
    data: {
        id: '',
        name: '',
        intern: '',
        regNo: '',
        checks: [],
        acceptanceInspectionChecks: [],
        inspectionType: 0,
        issuesCount: [],
        issues: [],
        certification: {
            type: '',
            lastDate: '',
            nextDate: '',
            isApproved: false
        },
        adrCertification: {
            type: '',
            lastDate: '',
            nextDate: '',
            isApproved: false
        },
        idleTimeHistoryData: [],
        fuelConsumptionHistoryData: [],
        inspectionData: [],
        idleHoursThresholdValues: {
            lowThresholdEnd: 0,
            middleThresholdEnd: 0,
            highThresholdEnd: 0
        },
        inspectionDataOfLast10Weeks: []
    },
    nextService: {},
    fetchMachine: thunk((actions, payload) => {
        actions.setFetching(true);
        return axios.get<Machine, Machine>(`${API.machines}/${payload}`)
            .then(data => {
                actions.fetchedMachine(data);
                actions.setOfflineMachine(data);
            }).finally(() => {
                actions.setFetching(false);
            });
    }),
    fetchedMachine: action((state, payload) => {
        state.data = payload;
    }),
    registerOperatingTimeOrCount: thunk((actions, payload) => {
        actions.setUpdating(true);
        return axios.patch<null, null>(`${API.machines}/operating-time`, payload)
            .then(data => {
                !payload.sync && actions.fetchMachine(payload.machineId);
                actions.setUpdating(false);
            })
            .finally(() => {
                actions.setUpdating(false);
            });
    }),
    fetchMachineService: thunk((actions, payload) => {
        actions.setMachineService({});
        return axios.post<any, any>(`${API.machinesService}`, {machineId: payload})
            .then(data => {
                if(data?.nextService){
                    actions.setMachineService(data.nextService)
                }
            });
    }),
    setMachineService: action((state, payload) => {
        state.nextService = payload
    }),
    fetchOfflineMachine: thunk(async (actions, payload) => {
        const MachineOffline = new MachineOfflineService();
        let machine = await MachineOffline.machineDetails(payload);
        machine && actions.fetchedMachine(machine);
    }),
    setOfflineMachine: thunk(async (actions, payload) => {
        const MachineOffline = new MachineOfflineService();
        await MachineOffline.updateMachineDetail(payload);
    }),
    offlineRegisterOperatingTimeOrCount: thunk(async (actions, payload) => {
        const MachineOffline = new MachineOfflineService();
        await MachineOffline.registerOperationTimeOrCount(payload);
        actions.fetchOfflineMachine(payload.machineId);
    }),
    fetchMachineDashboard: thunk((actions, payload) => {
        actions.setFetching(true);
        return axios.get<Machine, Machine>(`${API.machines}/${payload}/dashboard`)
            .then(data => {
                actions.fetchedMachineDashboard(data);
                actions.setOfflineMachineDashboard(data)
            }).finally(() => {
                actions.setFetching(false);
            });
    }),
    fetchedMachineDashboard: action((state, payload) => {
        state.data = {
            ...state.data,
            ...payload
        }
    }),
    fetchOfflineMachineDashboard: thunk(async (actions, payload) => {
        const MachineOffline = new MachineOfflineService();
        let machine = await MachineOffline.machineDetails(payload);
        machine && actions.fetchedMachineDashboard(machine);
    }),
    setOfflineMachineDashboard: thunk(async (actions, payload) => {
        const MachineOffline = new MachineOfflineService();
        await MachineOffline.updateMachineDashboardDetail(payload);
    }),
    initActiveTab: thunk((actions, payload, {getStoreState}) => {
        const userPermissions = getStoreState().user.permissions;
        const machineDashboardPermission = checkPermission(userPermissions, PERMISSION_GROUPS.machineDashboard);
        const machineRegistrationPermission = checkPermission(userPermissions, PERMISSION_GROUPS.machineRegistration);

        const defaultTab = getStoreState().machine.defaultTab;
        const activeTab = getStoreState().machine.activeTab;

        if(machineDashboardPermission && activeTab === MachineTabs.DASHBOARD) {
            actions.setActiveTab(MachineTabs.DASHBOARD);
        } else if(machineRegistrationPermission && activeTab === MachineTabs.REGISTRATION) {
            actions.setActiveTab(MachineTabs.REGISTRATION);
        } else if(machineDashboardPermission && defaultTab === MachineTabs.DASHBOARD) {
            actions.setActiveTab(MachineTabs.DASHBOARD);
        } else if(machineRegistrationPermission && defaultTab === MachineTabs.REGISTRATION) {
            actions.setActiveTab(MachineTabs.REGISTRATION);
        } else if(machineDashboardPermission || machineRegistrationPermission) {
            actions.setActiveTab(machineDashboardPermission ? MachineTabs.DASHBOARD : MachineTabs.REGISTRATION);
        }
    }),
    resetActiveTab: thunk((actions, payload, {getStoreState}) => {
        const userPermissions = getStoreState().user.permissions;
        const machineDashboardPermission = checkPermission(userPermissions, PERMISSION_GROUPS.machineDashboard);
        const machineRegistrationPermission = checkPermission(userPermissions, PERMISSION_GROUPS.machineRegistration);
        const defaultTab = getStoreState().machine.defaultTab;

        if(machineDashboardPermission && defaultTab === MachineTabs.DASHBOARD) {
            actions.setActiveTab(MachineTabs.DASHBOARD);
        } else if(machineRegistrationPermission && defaultTab === MachineTabs.REGISTRATION) {
            actions.setActiveTab(MachineTabs.REGISTRATION);
        }
    })
};
