export enum API {
    cargoTypes = '/transport-cargo-types',
    categories = '/categories',
    deliveryTimeRange = '/transport-delivery-time-range',
    projects = '/projects',
    requests = '/transport-requests',
    requestTypes = '/transport-request-types',

    //users
    users = '/users',
    allUsers = '/all-users',

    // transport
    trOrders = '/transport-orders',

    //machines
    machines = '/machines',
    //machine service
    machinesService = '/get_next_service',

    //issues
    issue = '/issue',
    periodicCheck = '/periodic-check',
    derpartments = '/departments',
    tenant = '/tenant',

    //notifications
    notifications = '/notifications',
}
